<template>
  <div class="main-block">
    <div class="white-box action-page">
      <div class="action-page--heading">
        <div class="d-flex justify-content-between flex-sm-column flex-column flex-md-row">
          <h1 class="action-page--heading__h d-flex align-items-center">
            <span>
              {{ $t('actionPages.titles.orders') }}
            </span>
          </h1>
          <div class="action-page--tip">
            <form
              class="orders-search d-flex align-items-center justify-content-between"
              @submit.prevent=""
            >
              <div class="icon">
                <span class="rbi rbi-filter" />
              </div>
              <div class="form-group">
                <input
                  v-model="searchName"
                  type="text"
                  :placeholder="$t('shared.placeholders.nameId')"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <multiselect
                  v-model="searchType"
                  :hide-selected="true"
                  :options="options"
                  :searchable="false"
                  track-by="k"
                  label="v"
                  :show-labels="false"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="action-page--content orders-page--content">
        <div v-show="showPreloader" class="alert-actions-wrap">
          <div class="loader--wrap">
            <div class="loader" />
          </div>
        </div>
        <div
          v-show="isSearching && !searchResult.length && !showLoadMore"
          class="alert-actions-wrap"
        >
          <p class="alert alert-warning">
            {{ $t('actionPages.errors.noSearchResults') }}
          </p>
        </div>
        <div
          v-show="!storedOrders.length && !showPreloader && !isSearching"
          class="alert-actions-wrap"
        >
          <p class="alert alert-info">
            {{ $t('actionPages.errors.noOrders') }}
          </p>
        </div>
        <div v-show="!showPreloader && orders.length" class="order-table--wrap">
          <table class="order-table">
            <tr class="order-table--header order-table--row">
              <td class="order-table--cell order-table--header__cell hidden-xs">
                <div class="relative">
                  {{ $t('actionPages.utils.serialNumber') }}
                  <button
                    v-if="!sSort.serial.active"
                    type="button"
                    class="btn-sort"
                    @click="tableSort('serial', 'desc')"
                  >
                    <span class="rbi rbi-sort" />
                  </button>
                  <button
                    v-if="sSort.serial.state === 'desc'"
                    type="button"
                    class="btn-sort"
                    @click="tableSort('serial', 'asc')"
                  >
                    <span class="rbi rbi-down-arrow" />
                  </button>
                  <button
                    v-if="sSort.serial.state === 'asc'"
                    type="button"
                    class="btn-sort"
                    @click="tableSort('serial', 'desc')"
                  >
                    <span class="rbi rbi-top-arrow" />
                  </button>
                </div>
              </td>
              <td class="order-table--cell order-table--header__cell hidden-xs">
                <div class="relative">
                  {{ $t('actionPages.utils.spentOn') }}
                  <button
                    v-if="!sSort.id.active"
                    type="button"
                    class="btn-sort"
                    @click="tableSort('id', 'desc')"
                  >
                    <span class="rbi rbi-sort" />
                  </button>
                  <button
                    v-if="sSort.id.state === 'desc'"
                    type="button"
                    class="btn-sort"
                    @click="tableSort('id', 'asc')"
                  >
                    <span class="rbi rbi-down-arrow" />
                  </button>
                  <button
                    v-if="sSort.id.state === 'asc'"
                    type="button"
                    class="btn-sort"
                    @click="tableSort('id', 'desc')"
                  >
                    <span class="rbi rbi-top-arrow" />
                  </button>
                </div>
              </td>
              <td class="order-table--cell order-table--header__cell responsive-width bdtop-l">
                <div class="relative">
                  {{ $t('actionPages.utils.type') }}
                  <button
                    v-if="!sSort.type.active"
                    type="button"
                    class="btn-sort"
                    @click="tableSort('type', 'desc')"
                  >
                    <span class="rbi rbi-sort" />
                  </button>
                  <button
                    v-if="sSort.type.state === 'desc'"
                    type="button"
                    class="btn-sort"
                    @click="tableSort('type', 'asc')"
                  >
                    <span class="rbi rbi-down-arrow" />
                  </button>
                  <button
                    v-if="sSort.type.state === 'asc'"
                    type="button"
                    class="btn-sort"
                    @click="tableSort('type', 'desc')"
                  >
                    <span class="rbi rbi-top-arrow" />
                  </button>
                </div>
              </td>
              <td class="order-table--cell order-table--header__cell hidden-xs">
                <div class="relative">
                  {{ $t('actionPages.utils.quantity') }}
                  <button
                    v-if="!sSort.quantity.active"
                    type="button"
                    class="btn-sort"
                    @click="tableSort('quantity', 'desc')"
                  >
                    <span class="rbi rbi-sort" />
                  </button>
                  <button
                    v-if="sSort.quantity.state === 'desc'"
                    type="button"
                    class="btn-sort"
                    @click="tableSort('quantity', 'asc')"
                  >
                    <span class="rbi rbi-down-arrow" />
                  </button>
                  <button
                    v-if="sSort.quantity.state === 'asc'"
                    type="button"
                    class="btn-sort"
                    @click="tableSort('quantity', 'desc')"
                  >
                    <span class="rbi rbi-top-arrow" />
                  </button>
                </div>
              </td>
              <td class="order-table--cell order-table--header__cell responsive-width">
                <div class="relative">
                  {{ $t('actionPages.utils.spentPay') }}
                  <button
                    v-if="!sSort.credits.active"
                    type="button"
                    class="btn-sort"
                    @click="tableSort('credits', 'desc')"
                  >
                    <span class="rbi rbi-sort" />
                  </button>
                  <button
                    v-if="sSort.credits.state === 'desc'"
                    type="button"
                    class="btn-sort"
                    @click="tableSort('credits', 'asc')"
                  >
                    <span class="rbi rbi-down-arrow" />
                  </button>
                  <button
                    v-if="sSort.credits.state === 'asc'"
                    type="button"
                    class="btn-sort"
                    @click="tableSort('credits', 'desc')"
                  >
                    <span class="rbi rbi-top-arrow" />
                  </button>
                </div>
              </td>
              <td class="order-table--cell order-table--header__cell hidden-xs">
                <div class="relative">
                  {{ $t('actionPages.utils.date') }}
                  <button
                    v-if="!sSort.date.active"
                    type="button"
                    class="btn-sort"
                    @click="tableSort('date', 'desc')"
                  >
                    <span class="rbi rbi-sort" />
                  </button>
                  <button
                    v-if="sSort.date.state === 'desc'"
                    type="button"
                    class="btn-sort"
                    @click="tableSort('date', 'asc')"
                  >
                    <span class="rbi rbi-down-arrow" />
                  </button>
                  <button
                    v-if="sSort.date.state === 'asc'"
                    type="button"
                    class="btn-sort"
                    @click="tableSort('date', 'desc')"
                  >
                    <span class="rbi rbi-top-arrow" />
                  </button>
                </div>
              </td>
              <td
                class="order-table--cell order-table--header__cell text-center bdtop-r visible-td-xs"
              >
                <span class="d-flexorder-table--filter-sign">
                  <span class="rbi rbi-sort m0" />
                </span>
              </td>
            </tr>
            <template v-for="(item, index) in orders">
              <tr :key="`ord-itemx-${index}`" class="order-table--row">
                <td class="order-table--cell hidden-xs">
                  {{ item.order.id }}
                </td>
                <td class="order-table--cell hidden-xs">
                  <router-link :to="{ name: 'profile', params: { id: item.user.id } }">
                    {{ item.user.name }}
                  </router-link>
                  ,<br />ID: {{ item.user.id }}
                </td>
                <td class="order-table--cell">
                  <component :is="item.order.action" />
                </td>
                <td class="order-table--cell hidden-xs">
                  {{ displayedQuantity(item.order.quantity, item.order.action) }}
                </td>
                <td class="order-table--cell">
                  {{ item.order.credit }}
                </td>
                <td class="order-table--cell hidden-xs">
                  {{ displayDate(item.order.created_at) }}
                </td>
                <td
                  class="order-table--cell toggle-state text-center visible-td-xs"
                  :class="{ open: item.open }"
                  @click="item.open = !item.open"
                >
                  <span v-if="!item.open" class="rbi rbi-down-arrow m0" />
                  <span v-if="item.open" class="rbi rbi-top-arrow m0" />
                </td>
              </tr>
              <tr
                :key="`ord-itemy-${index}`"
                class="order-table--row order-table--hidden"
                :class="{ open: item.open }"
              >
                <td colspan="6">
                  <div class="order-table--responsive-record__wrap">
                    <div class="order-table--responsive-record">
                      <div class="order-table--responsive-record__item">
                        {{ $t('actionPages.utils.serialNumber') }}:
                      </div>
                      <div class="order-table--responsive-record__item">
                        {{ item.order.id }}
                      </div>
                    </div>
                    <div class="order-table--responsive-record">
                      <div class="order-table--responsive-record__item">
                        {{ $t('actionPages.utils.spentOn') }}:
                      </div>
                      <div class="order-table--responsive-record__item">
                        <router-link :to="{ name: 'profile', params: { id: item.user.id } }">
                          {{ item.user.name }} </router-link
                        >, ID: {{ item.user.id }}
                      </div>
                    </div>
                    <div class="order-table--responsive-record">
                      <div class="order-table--responsive-record__item">
                        {{ $t('actionPages.utils.quantity') }}:
                      </div>
                      <div class="order-table--responsive-record__item">
                        {{ displayedQuantity(item.order.quantity, item.order.action) }}
                      </div>
                    </div>
                    <div class="order-table--responsive-record">
                      <div class="order-table--responsive-record__item">
                        {{ $t('actionPages.utils.date') }}:
                      </div>
                      <div class="order-table--responsive-record__item">
                        {{ displayDate(item.order.created_at) }}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </table>
          <div
            v-if="!showPreloader && storedOrders.length && showLoadMore && !isSearching"
            class="button-wrap"
          >
            <lazy-load :counter="defaultLimit" @click.native="loadMore" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import moment from 'moment';
import LazyLoad from './parts/LoadMore.vue';
import TemplateChatPrivate from './ordersPage/TemplateChatPrivate.vue';
import TemplateChatText from './ordersPage/TemplateChatText.vue';
import TemplateChatVideo from './ordersPage/TemplateChatVideo.vue';
import TemplateGift from './ordersPage/TemplateGift.vue';
import TemplateInfo from './ordersPage/TemplateInfo.vue';
import TemplatePhotoPrivate from './ordersPage/TemplatePhotoPrivate.vue';
import TemplateLetter from './ordersPage/TemplateLetter.vue';

export default {
  data() {
    return {
      showLoadMore: true,
      showPreloader: true,
      searchState: false,
      searchName: '',
      searchType: {
        k: '',
        v: this.$t('actionPages.utils.type'),
      },
      isSearching: false,
      storedOrders: [],
      searchResult: [],
      defaultLimit: 30,
      offset: 0,
      options: [
        {
          k: '',
          v: this.$t('actionPages.utils.type'),
        },
        {
          k: 'text_chat',
          v: this.$t('actionPages.orderTypes.chatText'),
        },
        {
          k: 'video_chat',
          v: this.$t('actionPages.orderTypes.chatVideo'),
        },
        {
          k: 'private_chat',
          v: this.$t('actionPages.orderTypes.chatPrivate'),
        },
        {
          k: 'write_letter',
          v: this.$t('actionPages.orderTypes.letter'),
        },
        {
          k: 'realinfo_request',
          v: this.$t('actionPages.orderTypes.info'),
        },
        {
          k: 'send_gift',
          v: this.$t('actionPages.orderTypes.gift'),
        },
        {
          k: 'private_photo_buy',
          v: this.$t('actionPages.orderTypes.photoBuy'),
        },
      ],
      sSort: {
        type: {
          active: false,
          state: '',
        },
        id: {
          active: false,
          state: '',
        },
        serial: {
          active: false,
          state: '',
        },
        date: {
          active: false,
          state: '',
        },
        credits: {
          active: false,
          state: '',
        },
        quantity: {
          active: false,
          state: '',
        },
      },
    };
  },
  watch: {
    /**
     * Получить данные, когда профиль пользователя загружен
     */
    sex() {
      this.getOrders();
    },

    /**
     * Запуск поиск по имени или сброс состояния поиска
     * @param val {string}
     */
    searchName(val) {
      this.offset = 0;
      if (val) {
        this.showPreloader = true;
        this.isSearching = true;
        this.startSearch();
      } else if (!this.searchType) {
        this.showPreloader = true;
        this.isSearching = true;
        this.search();
      } else {
        this.searchType = '';
        // then fires searchType watcher *else*
      }
      this.showLoadMore = true;
    },

    /**
     * Запустить функциою для фильтрации показываемых данных в зависимости от значения селекта или очистить состояние
     * @param val {string}
     */
    searchType(val) {
      this.offset = 0;
      if (val) {
        this.showPreloader = true;
        this.isSearching = true;
        this.startSearch();
      } else {
        this.showPreloader = true;
        this.isSearching = true;
        this.startSearch();
      }
      this.showLoadMore = true;
    },
  },
  methods: {
    /**
     * Показать больше записей
     */
    loadMore() {
      const { length } = this.orders;
      this.offset += length < this.defaultLimit ? length : this.defaultLimit;
      this.search(this.offset);
    },

    /**
     * Запуск поиска с задержкой в 300мс
     */
    startSearch: debounce(function () {
      this.search();
    }, 300),

    /**
     * Поиск по всем заказам
     * @param offset {number} - с какого элемента начинать поиск
     */
    search(offset) {
      this.$http
        .post('v1/credit/order', {
          access_token: window.localStorage['access-token'],
          limit: this.defaultLimit,
          offset: offset || 0,
          sort_date: this.sSort.date.state,
          sort_type: this.sSort.type.state,
          sort_serial_number: this.sSort.serial.state,
          sort_user_id: this.sSort.id.state,
          sort_quantity: this.sSort.quantity.state,
          sort_spent_credits: this.sSort.credits.state,
          search_name_or_id: this.searchName,
          search_order_type: this.searchType ? this.searchType.k : '',
        })
        .then(
          (response) => {
            this.isSearching = false;
            if (response.body.status) {
              this.showPreloader = false;
              response.body.result.length < this.defaultLimit
                ? (this.showLoadMore = false)
                : (this.showLoadMore = true);
              this.orders = response.body.result;
            } else {
              this.$store.commit('addAlert', {
                type: 'error',
                text: this.$t('alerts.someErr'),
                info: 'load orders',
              });
            }
          },
          () => {
            this.isSearching = false;
            this.$store.commit('addAlert', {
              type: 'error',
              text: this.$t('alerts.someErr'),
              info: 'load orders',
            });
          }
        );
    },

    /**
     * Получить все данные о заказах пользователя
     */
    getOrders() {
      if (this.sex.isMan) {
        this.showPreloader = true;
        this.$http
          .post('v1/credit/order', {
            access_token: window.localStorage['access-token'],
            limit: this.defaultLimit,
            offset: 0,
            sort_date: 'desc',
          })
          .then(
            (response) => {
              if (response.body.status) {
                this.showPreloader = false;
                this.orders = response.body.result;
                response.body.result.length < this.defaultLimit
                  ? (this.showLoadMore = false)
                  : (this.showLoadMore = true);
              } else {
                this.$store.commit('addAlert', {
                  type: 'error',
                  text: this.$t('alerts.someErr'),
                  info: 'load orders',
                });
              }
            },
            () => {
              this.$store.commit('addAlert', {
                type: 'error',
                text: this.$t('alerts.someErr'),
                info: 'load orders',
              });
            }
          );
      }
      this.preventEnter();
    },

    /**
     * Перекинуть на 404ю страницу, если пользователь - женщина
     */
    preventEnter() {
      if (this.sex.isWoman) {
        this.$router.push({
          name: '404',
        });
      }
    },

    /**
     * Получить отображение даты
     * @param timestamp {number} время события
     * @return {string}
     */
    displayDate(timestamp) {
      return moment(timestamp * 1000).format('H:mm:ss MMM Do, YYYY ');
    },

    /**
     * Получить значение "количество" для записи
     * @param count {number}
     * @param type {string}
     * @return {string}
     */
    displayedQuantity(count, type) {
      const tmp = `${type}`; // make string in all cases
      return tmp.match(/chat/) ? `${count} ${this.$t('actionPages.utils.minutes')}` : count;
    },
    /**
     * Очистить состояние сортировки
     */
    resetSort() {
      this.offset = 0;
      for (const key in this.sSort) {
        Object.assign(this.sSort[key], {
          active: false,
          state: '',
        });
      }
    },
    /**
     * Сортировать данные
     * @param type {string}: [quantity, credits, date, serial, id, type, ]
     * @param state {string}: [asc, desc]
     */
    tableSort(type, state) {
      this.resetSort();
      const parent = this.sSort[type];
      const obj = {
        active: true,
        state,
      };
      Object.assign(parent, obj);
      this.search();
    },
  },
  computed: {
    emptyResult() {
      if (this.isSearching && !this.searchResult.length) {
        return true;
      }
      return false;
    },
    sex() {
      return this.$store.getters.sex;
    },
    /**
     * Получить список заказов или записатать их в массив
     */
    orders: {
      get() {
        return this.storedOrders;
      },
      set(val) {
        val.forEach((i) => (i.open = false));
        if (!this.offset) {
          this.storedOrders = val;
        } else {
          this.storedOrders = this.storedOrders.concat(val);
        }
      },
    },
  },
  components: {
    write_letter: TemplateLetter,
    text_chat: TemplateChatText,
    video_chat: TemplateChatVideo,
    private_chat: TemplateChatPrivate,
    realinfo_request: TemplateInfo,
    send_gift: TemplateGift,
    private_photo_buy: TemplatePhotoPrivate,
    LazyLoad,
  },
  mounted() {
    document.title = this.$t('actionPages.titles.orders');
    this.getOrders();
  },
  created() {
    this.preventEnter();
  },
};
</script>

<style lang="scss" scoped>
.icon {
  font-size: 20px;
}

.form-group {
  width: 194px;
  margin-left: 15px;

  .form-control {
    width: 100%;
  }
}

.relative {
  padding-top: 0;
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
  .icon {
    display: none;
  }

  .form-group {
    width: 48%;
    margin-left: 0;
  }

  .action-page--heading {
    .set-flex {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
    .action-page--tip {
      width: 100%;
      margin-top: 15px;
      .set-flex {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
}
</style>
