<template>
  <transition name="modal">
    <div
      v-if="modal"
      class="vue-modal modal-mask pay_system-modal"
      @click="closeModal($event.target, false)"
    >
      <div class="vue-modal--wrapper">
        <div class="vue-modal--container pay_system-modal--container">
          <button type="button" class="close" @click="closeModal(false)">
            <span class="rbi rbi-close" />
          </button>
          <div class="modal-body pay_system-modal--body">
            <h3>To buy {{ pack.credit }} credits for {{ pack.price }}€</h3>
            <h4 class="modal-title">
              {{ $t('paySystemModal.heading') }}
            </h4>
            <div class="pay_systems">
              <div v-if="paySystemsStatus.stripe_online" class="pay_system">
                <h6 class="subheader">Stripe</h6>
                <input
                  id="stripe"
                  v-model="actionUrl"
                  type="radio"
                  name="radio-group"
                  value="stripe"
                />
                <label for="stripe">
                  <span class="pay_system--block">
                    <img src="../../../assets/images/mastecard-visa@compress-h37.png" alt />
                  </span>
                </label>
              </div>
              <div v-if="paySystemsStatus.billline_online" class="pay_system">
                <h6 class="subheader">Bill Line UA Hryvnas</h6>
                <input
                  id="billline"
                  v-model="actionUrl"
                  type="radio"
                  name="radio-group"
                  value="billline"
                />
                <label for="billline">
                  <span class="pay_system--block">
                    <img src="../../../assets/images/mastecard-visa@compress-h37.png" alt />
                  </span>
                </label>
              </div>
              <div v-if="paySystemsStatus.billline_usd_online" class="pay_system">
                <h6 class="subheader">Bill Line US Dollars</h6>
                <input
                  id="billline_usd"
                  v-model="actionUrl"
                  type="radio"
                  name="radio-group"
                  value="billline-usd"
                />
                <label for="billline_usd">
                  <span class="pay_system--block">
                    <img src="../../../assets/images/mastecard-visa@compress-h37.png" alt />
                  </span>
                </label>
              </div>
              <div v-if="paySystemsStatus.multicard_online" class="pay_system">
                <h6 class="subheader">MultiCards</h6>
                <input
                  id="multicard"
                  v-model="actionUrl"
                  type="radio"
                  name="radio-group"
                  value="multicard"
                />
                <label for="multicard">
                  <span class="pay_system--block">
                    <img src="../../../assets/images/mastecard-maestro@compress-h371.png" alt />
                  </span>
                </label>
              </div>
              <div v-if="paySystemsStatus.epayments_online" class="pay_system">
                <h6 class="subheader">Epayments</h6>
                <input
                  id="epay"
                  v-model="actionUrl"
                  type="radio"
                  name="radio-group"
                  value="epayments"
                />
                <label for="epay">
                  <span class="pay_system--block">
                    <img src="../../../assets/images/mastecard-maestro@compress-h37.png" alt />
                  </span>
                </label>
              </div>
              <div v-if="paySystemsStatus.segpay_online" class="pay_system">
                <h6 class="subheader">Segpay</h6>
                <input
                  id="segpay"
                  v-model="actionUrl"
                  type="radio"
                  name="radio-group"
                  value="segpay"
                />
                <label for="segpay">
                  <span class="pay_system--block">
                    <img src="../../../assets/images/mastecard-maestro@compress-h37.png" alt />
                  </span>
                </label>
              </div>
              <div v-if="paySystemsStatus.paymentstrust_online" class="pay_system">
                <h6 class="subheader">Payments Trust</h6>
                <input
                  id="paymentstrust"
                  v-model="actionUrl"
                  type="radio"
                  name="radio-group"
                  value="paymentstrust"
                />
                <label for="paymentstrust">
                  <span class="pay_system--block">
                    <img src="../../../assets/images/mastecard-maestro@compress-h37.png" alt />
                  </span>
                </label>
              </div>
              <div v-if="paySystemsStatus.ccbill_online" class="pay_system">
                <h6 class="subheader">CCBill</h6>
                <input
                  id="ccbill"
                  v-model="actionUrl"
                  type="radio"
                  name="radio-group"
                  value="ccbill"
                />
                <label for="ccbill">
                  <span class="pay_system--block">
                    <img src="../../../assets/images/mastecard-maestro@compress-h37.png" alt />
                  </span>
                </label>
              </div>
            </div>
            <!--            <div v-else class="loader-wrap">
              <preloader />
            </div>-->
          </div>
          <div>
            <div class="modal-body pay_system-modal--body-btn d-flex flex-wrap align-items-center">
              <rb-button
                class="btn-black"
                :disabled="!accept || !actionUrl"
                @click.native="accepted"
              >
                <span :class="{ wait: entering }">{{ $t('paySystemModal.accept') }}</span>
                <span v-if="entering" :class="{ 'wait-spin': entering }" />
              </rb-button>
              <!--              <a :href="actionUrl" :disabled="!accept" class="btn btn-black" :class="{disabled: !accept}" target="_blank" @click="closeModal(false)">-->
              <!--                {{$t('paySystemModal.accept')}}-->
              <!--              </a>-->
              <div class="custom-checkbox">
                <input
                  id="cbx"
                  v-model="accept"
                  name="terms"
                  class="inp-cbx"
                  type="checkbox"
                  style="display: none"
                />
                <label class="cbx" for="cbx">
                  <span>
                    <svg width="12px" height="10px" viewBox="0 0 12 10">
                      <polyline points="1.5 6 4.5 9 10.5 1" />
                    </svg>
                  </span>
                  <span>
                    {{ $t('guest.indexHeader.agreement.text') }}
                    <router-link :to="{ name: 'terms' }">
                      {{ $t('guest.indexHeader.agreement.link') }} </router-link
                    >and
                    <router-link :to="{ name: 'refund' }" :title="$t('shared.menu.refund')">{{
                      $t('shared.menu.refund').toLowerCase()
                    }}</router-link>
                  </span>
                </label>
                <transition name="slide-fade">
                  <div v-show="errors.has('indHreg.terms')" class="error">
                    <span class="help is-danger">{{ errors.first('indHreg.terms') }}</span>
                  </div>
                </transition>
              </div>
            </div>
            <div class="modal-footer">
              <p class="text-center">
                Your statement will read
                <router-link to="/"> redbini.com </router-link>
                <!--<router-link to="/">Redbini {{settings.company_city}}</router-link>.-->
              </p>
              <p class="text-center">{{ settings.company_name }}, {{ settings.company_address }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import RbButton from '../../Button';

export default {
  name: 'PaySystem',
  components: {
    RbButton,
  },
  props: {
    pack: Object,
  },
  data() {
    return {
      accept: false,
      entering: false,
      actionUrl: '',
      packUpdate: {},
      load: true,
    };
  },
  computed: {
    paySystemsStatus() {
      const userPaySystems =
        this.$store.getters.userPaymentsGroup && this.$store.getters.userPaymentsGroup.length > 0
          ? this.$store.getters.userPaymentsGroup
          : null;
      console.log(this.$store.getters.siteSettings.stripe_online)
      const paySystemsOnlineStatus = [
        {
          id: 1,
          onlineStatus: !!this.$store.getters.siteSettings.multicard_online,
        },
        {
          id: 2,
          onlineStatus: !!this.$store.getters.siteSettings.segpay_online,
        },
        {
          id: 3,
          onlineStatus: !!this.$store.getters.siteSettings.epayments_online,
        },
        {
          id: 4,
          onlineStatus: !!this.$store.getters.siteSettings.ccbill_online,
        },
        {
          id: 5,
          onlineStatus: !!this.$store.getters.siteSettings.paymentstrust_online,
        },
        {
          id: 6,
          onlineStatus: !!this.$store.getters.siteSettings.billline_online,
        },
        {
          id: 7,
          onlineStatus: !!this.$store.getters.siteSettings.billline_usd_online,
        }, {
          id: 8,
          onlineStatus: !!this.$store.getters.siteSettings.stripe_online,
        },
      ];

      if (userPaySystems) {
        paySystemsOnlineStatus.forEach((paySystem) => {
          const foundActiveUserPaySystem = userPaySystems.find(
            (userPaySystem) => userPaySystem === paySystem.id
          );
          paySystem.onlineStatus = !!(foundActiveUserPaySystem && paySystem.onlineStatus === true);
        });
      }
      console.log(paySystemsOnlineStatus[7])
      return {
        multicard_online: paySystemsOnlineStatus[0].onlineStatus,
        segpay_online: paySystemsOnlineStatus[1].onlineStatus,
        epayments_online: paySystemsOnlineStatus[2].onlineStatus,
        ccbill_online: paySystemsOnlineStatus[3].onlineStatus,
        paymentstrust_online: paySystemsOnlineStatus[4].onlineStatus,
        billline_online: paySystemsOnlineStatus[5].onlineStatus,
        billline_usd_online: paySystemsOnlineStatus[6].onlineStatus,
        stripe_online: paySystemsOnlineStatus[7].onlineStatus,
      };
    },
    modal() {
      return this.$store.getters.paySystemModal.open;
    },
    settings() {
      return this.$store.getters.siteSettings;
    },
    sex() {
      return this.$store.getters.sex;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  watch: {
    modal(v) {
      if (v) {
        // this.generateLink()
      }
    },
  },
  methods: {
    async generateLink(activeUrl) {
      return new Promise((resolve, reject) => {
        const requestData = {
          access_token: window.localStorage['access-token'],
          credit_pack_id: this.pack.id,
        };
        console.log(reject,resolve)
        if (activeUrl === 'segpay') {
          // SegPay price point
          Object.assign(requestData, {
            price_point_id: this.pack.segpay_price_point_id,
          });
        }

        this.$http
          .post(`v1/${activeUrl}/generate-payment-url`, requestData)
          .then((response) => {
            if (!response || !response.body) reject(new Error('Wrong response'));
            if (typeof response.body === 'string' && response.body.length > 0) {
              resolve(response.body);
            } else {
              reject(new Error('Wrong response'));
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    setActive() {
      // if(this.paySystemsStatus.multicard_online) {
      //   this.actionUrl = this.packUpdate.multicardLink
      // } else if(this.paySystemsStatus.epayments_online) {
      //   this.actionUrl = this.packUpdate.segpayLink
      // } else if(this.paySystemsStatus.ccbill_online) {
      //   this.actionUrl = this.packUpdate.ccbill
      // }
      // console.log(this.actionUrl)
    },

    /**
     * закрыть модалку
     *
     *
     * @param e {object} event
     */
    closeModal(e) {
      if (
        // eslint-disable-next-line no-undef
        (!$(e).closest('.pay_system-modal--body-btn').length &&
          // eslint-disable-next-line no-undef
          !$(e).closest('.pay_system-modal--body').length) ||
        !e
      ) {
        this.actionUrl = '';
        this.$store.commit('updatePaySystemModal', {
          open: false,
        });
        this.load = true;
      }
    },
    async accepted() {
      this.entering = true;
      if (!this.actionUrl) {
        this.closeModal(false);
        return;
      }
      try {
        const payUrl = await this.generateLink(this.actionUrl);

        if (!payUrl || payUrl.length < 1) {
          this.$store.dispatch('addAlert', {
            type: 'error',
            text: this.$t('alerts.paySystemLinkError'),
            info: 'paySystemLinkError',
          });

          return new Error('Wrong url.');
        }

        /* if (this.pack.isPromo) {
          this.$gtag.event('promo-pack-pay-start', {
            method: 'promo',
            packId: this.pack.id,
            user: this.user.user_id,
          }); // Analytic
        } */
        window.location = payUrl;

        // window.open(payUrl, '_blank')
      } catch (e) {
        this.$store.dispatch('addAlert', {
          type: 'error',
          text: this.$t('alerts.paySystemLinkError'),
          info: 'paySystemLinkError',
        });
        console.error(e);
        this.entering = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../assets/scss/vars';
.wait-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0);
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-left-color: transparent;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  animation: spin 1s infinite linear;
}
@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.loader-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0 0;
}

.pay_systems {
  margin-top: 45px;
}

.subheader {
  font-size: 14px;
  margin-bottom: 10px;
  padding: 5px;
  /*border-bottom: 1px solid #eaeaea;*/
}

label {
  margin-bottom: 0;
}

.pay_system {
  margin-bottom: 15px;

  &--block {
    height: 30px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    margin-left: 0px;

    img {
      height: 100%;
      object-fit: contain;
      max-width: 100%;
    }
  }
}

.modal-footer {
  padding: 10px 40px 10px;
  border-top: 1px solid #f5f5f5;
  p {
    margin-bottom: 10px;
    font-size: 12px;
    color: #808080;
  }
}

.btn-black {
  background: #000;
  color: #fff !important;
  text-decoration: none;
}

[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 26px;
  height: 26px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  width: 8px;
  height: 8px;
  background: #e21919;
  position: absolute;
  top: calc(50% - 4px);
  transform: translateY(-50%);
  left: 9px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/*  [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
    }

    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
      position: relative;
      padding-left: 38px;
      cursor: pointer;
      line-height: 25px;
      display: inline-block;
      color: #666;
    }

    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 30px;
      height: 30px;
      border: 1px solid #ddd;
      border-radius: 100%;
      background: #fff;
      box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
    }

    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      content: '';
      width: 8px;
      height: 8px;
      background: #e21919;
      position: absolute;
      top: calc(50% - 4px);
      transform: translateY(-50%);
      left: 11px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }

    [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }*/

.pay_system-modal {
  padding: 15px;

  &--container {
    width: 690px;
    position: relative;
    padding: 0;
    margin-top: 100px;
  }

  h4 {
    font-weight: bold;
    font-size: 18px;
    color: #3e3f42;
  }

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  &--body {
    padding: 35px 45px 0;
    position: static;

    p {
      margin: 20px 0;
    }
  }

  &--body-btn {
    padding: 15px 45px 35px;

    .custom-checkbox {
      margin-left: 20px;
    }
  }

  .btn-primary {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none !important;
    padding: 10px 20px;

    span + span {
      margin-left: 10px;
    }

    svg {
      height: 20px;
      width: 20px;
      fill: currentColor;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .vue-modal--wrapper {
    padding-top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .vue-modal--wrapper {
    padding-top: 120px;
  }
  .modal-footer {
    padding: 10px 15px;
  }
  .pay_system-modal {
    &--container {
      width: 100% !important;
      margin-top: 0;
    }

    &--body {
      padding: 20px 20px 0;
      position: static;

      p {
        margin: 20px 0;
      }
    }

    &--body-btn {
      padding: 0 15px 20px;
    }
  }
}

.cbx span:first-child {
  margin-right: 7px;
  min-width: 21px;
}
.cbx span:last-child {
  padding-left: 0;
}
.cbx {
  display: flex;
}
.cbx span {
  word-break: break-word;
}

@media only screen and (max-width: 550px) {
  .vue-modal--wrapper {
    padding-top: 120px;
  }
  .pay_system-modal {
    &--container {
      width: 100% !important;
      margin-top: 0;
    }

    &--body {
      padding: 20px 20px 0;
      position: static;

      p {
        margin: 20px 0;
      }
    }

    &--body-btn {
      padding: 0 15px 20px;

      .custom-checkbox {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }
}
</style>
