<template>
  <div class="main-block">
    <div id="veryWhiteBox" class="white-box action-page" style="height: 100%">
      <div v-if="creditsBuyType !== 'promo'" class="action-page--heading">
        <div class="set-flex set-flex-x-between">
          <h1 class="action-page--heading__h set-flex set-flex-y-center">
            <span>
              {{ $t('actionPages.titles.addCredits') }}
            </span>
          </h1>
        </div>
      </div>

      <div v-if="!isWoman" class="action-page--content">
        <div class="credits-box" style="padding: 0">
          <transition name="fade">
            <div v-show="!showPreloader && !showError">
              <component
                :is="creditsBuyType"
                @loading="showPreloader = $event"
                @error="showError = $event"
                @pack-selected="selectPack = $event"
                @no-promo-packs="promoPacksAvailable = false"
              />
            </div>
          </transition>
          <div v-show="showPreloader" class="loader--wrap">
            <div class="loader" />
          </div>

          <div v-show="showError">Error happened...</div>
        </div>
      </div>
    </div>
    <pay-system :pack="selectPack" />
  </div>
</template>

<script>
import PaySystem from '../../Shared/modals/PaySystem.vue';
import Standard from './Standard.vue';
import Promo from './Promo.vue';

export default {
  components: {
    Promo,
    Standard,
    PaySystem,
  },

  data() {
    return {
      showPreloader: false,
      showError: false,
      selectPack: {},
      questionTimer: null,
      testPromo: false,
      promoPacksAvailable: true,
    };
  },

  computed: {
    creditsBuyType() {
      return this.$store.getters.settings &&
        this.$store.getters.settings.is_promo_purchased === 0 &&
        this.promoPacksAvailable
        ? 'promo'
        : 'standard'; // promo || standard
    },

    questionnaireStatus() {
      return this.$store.getters.questionnaireStatus;
    },

    paySystemsStatus() {
      return {
        multicard_online: !!this.$store.getters.siteSettings.multicard_online,
        segpay_online: !!this.$store.getters.siteSettings.segpay_online,
        epayments_online: !!this.$store.getters.siteSettings.epayments_online,
        ccbill_online: !!this.$store.getters.siteSettings.ccbill_online,
        paymentstrust_online: !!this.$store.getters.siteSettings.paymentstrust_online,
        billline_online: !!this.$store.getters.siteSettings.billline_online,
        billline_usd_online: !!this.$store.getters.siteSettings.billline_usd_online,
      };
    },

    filterPay() {
      return Object.keys(this.paySystemsStatus).filter((key) => this.paySystemsStatus[key]);
    },

    settings() {
      return this.$store.getters.siteSettings;
    },

    isWoman() {
      return this.$store.getters.sex.isWoman;
    },

    user() {
      return this.$store.getters.user;
    },
  },

  watch: {
    isWoman(value) {
      if (value) {
        this.$router.push('/');
      }
    },
    questionnaireStatus(value) {
      if(value) this.openQuestionnaireWithDelay(1000);
    }
  },

  mounted() {
    if (this.isWoman) {
      this.$router.push('/');
    }

    document.title = this.$t('actionPages.titles.addCredits');

    if (this.questionnaireStatus) {
      console.log('Questionnaire status', this.questionnaireStatus)
      this.openQuestionnaireWithDelay(1000);
    }
  },

  beforeDestroy() {
    clearTimeout(this.questionTimer);
  },

  methods: {
    openQuestionnaireWithDelay(delay) {
      this.questionTimer = setTimeout(() => {
        this.$store.commit('updateQuestionnaireModal', {
          open: true,
        });
      }, delay);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/vars';

.loader--wrap {
  padding: 50px 0;
}

svg {
  width: 20px;
  height: 20px;
}

@font-face {
  font-weight: bold;
  font-family: 'BebasNeueBold';
  src: url('/static/fonts/BebasNeue Bold.ttf') format('truetype'),
    url('/static/fonts/BebasNeue Bold.otf') format('opentype');
}

.credits-box {
  &__discount {
    font-family: 'BebasNeueBold', 'Roboto', 'Lato', 'sans-serif';
    font-weight: 900;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
