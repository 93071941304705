<template>
  <div
    v-if="!loading && packs.length > 0"
    class="row justify-content-center"
    style="padding: 0 25px"
  >
    <form
      v-for="pack in packs"
      :key="pack.id"
      action="https://secure.multicards.com/cgi-bin/order2/processorder1.pl"
      class="col-lg-6 col-md-12 col-sm-12"
      method="post"
    >
      <div
        :class="{ action: pack.limit }"
        :data-selection="$t('shared.utils.hot')"
        class="credits-box__item"
      >
        <div class="credits-box__item__inner credits-pack">
          <div class="credits-pack__heading">
            <div class="credits-pack__img">
              <img :alt="pack.name + ' pack'" :src="pack.photo" />
            </div>
            <h2 class="credits-pack__title">
              {{ pack.name }}
            </h2>
          </div>

          <div class="credits-pack__info">
            <div class="credits-pack__info-item">
              <div class="info">
                <div class="money-icon">
                  <span class="rbi rbi-money" />
                </div>
                <div class="credits-pack__price">
                  <span class="count">{{ pack.credit }}</span>
                  <div class="price">
                    <span class="primary">{{ pack.price }}€</span>
                    <span v-if="pack.old_price && pack.old_price !== pack.price" class="discount">
                      {{ pack.old_price }}€
                    </span>
                  </div>
                </div>
              </div>
              <rb-button
                style="color: black"
                :title="$t('actionPages.utils.buyCredits', [pack.credit])"
                type="submit"
                @click.native.prevent="openPaySystemModal(pack)"
              >
                {{ $t('actionPages.utils.buy') }}
              </rb-button>
            </div>
          </div>

          <p v-if="pack.old_price && pack.old_price !== pack.price" class="credits-box__label">
            {{ discount(pack.price, pack.old_price) }}% <span>off</span>
          </p>

          <p v-if="pack.limit" class="credits-box__label promo">PROMO</p>

          <div v-if="pack.bestseller" class="credits-box__bestseller">
            <span>Bestseller</span>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div v-else />
</template>

<script>
import RbButton from '../../Button';
import creditPacks from '../../../mixins/creditPacks';

export default {
  name: 'Standard',

  components: {
    RbButton,
  },

  mixins: [creditPacks],

  data: () => ({
    packs: [],
  }),

  async mounted() {
    try {
      this.loading = true;
      await this.loadPacks();
    } catch (e) {
      this.error = true;
      await this.$store.dispatch('addAlert', {
        type: 'error',
        text: this.$t('alerts.someErr'),
        info: 'load credit packs',
      });
    } finally {
      this.loading = false;
    }
  },

  methods: {
    /**
     * Посчитать скидку в %
     *
     */
    discount(newPrice, oldPrice) {
      return Math.round((100 * newPrice) / oldPrice - 100);
    },
    /**
     * Загрузить кредитпаки
     */
    async loadPacks() {
      const { body } = await this.$http.post('v1/credit/load', {
        access_token: window.localStorage['access-token'],
      });

      if (!body.status) {
        throw new Error('Something went wrong');
      }

      this.packs = body.result;
    },
    /**
     * Открыть модальное окно выбора платежной системы
     */
    async openPaySystemModal(pack) {
      await this.$emit('pack-selected', pack);
      setImmediate(() => {
        this.$store.commit('updatePaySystemModal', {
          open: true,
        });
      });
    },
  },
};
</script>

<style scoped></style>
