const creditPacks = {
  data: () => ({
    loading: false,
    error: false,
  }),
  watch: {
    loading(value) {
      this.$emit('loading', value);
    },
    error(value) {
      this.$emit('error', value);
    },
  },
};

export default creditPacks;
