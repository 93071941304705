<template>
  <div>
    <div style="display: flex; justify-content: center">
      <div>
        <div class="credits-pack__heading promo-pack-heading">
          <div class="credits-pack__img">
            <img :alt="pack.name + ' pack'" :src="pack.photo" />
          </div>
        </div>
        <h2 class="credits-pack__title">
          {{ pack.name }}
        </h2>
        <div class="credits-pack__info">
          <div class="promo-info-item">
            <div class="promo-info">
              <div
                class="money-icon"
                style="display: flex; justify-content: center; flex-direction: column"
              >
                <span class="rbi rbi-money" style="font-size: 1.5rem; margin-right: 5px" />
              </div>
              <div class="credits-pack__price">
                <span class="count">{{ pack.credit }}</span>
                <div class="price">
                  <span class="primary">{{ pack.price }}€</span>
                  <span v-if="pack.old_price && pack.old_price !== pack.price" class="discount">
                    {{ pack.old_price }}€
                  </span>
                </div>
              </div>
            </div>
            <div class="promo-pack-button">
              <rb-button
                style="color: black"
                :title="$t('actionPages.utils.buyCredits', [pack.credit])"
                type="submit"
                @click.native.prevent="openPaySystemModal"
              >
                Credit Package for {{ pack.price }} €
              </rb-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="promo-tab-text">
      <p>We have an offer for you! You can get a credit package for €{{ pack.price }}!</p>
      <p>There will be {{ pack.credit }} credits that you can spend on:</p>

      <ul style="list-style: square">
        <li v-if="!isNaN(willHaveChatMinutes)">{{ willHaveChatMinutes }} chat minutes;</li>
        <li v-if="!isNaN(willHaveChatMinutes)">{{ canSendLetters }} messages;</li>
        <li v-if="!isNaN(willHaveChatMinutes)">{{ willHaveVideoChatMinutes }} video minutes.</li>
      </ul>
    </div>
  </div>
</template>

<script>
import RbButton from '../../Button';

export default {
  name: 'PromoTab',

  components: {
    RbButton,
  },

  props: {
    pack: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    /**
     * Посчитать скидку в %
     *
     */
    discount() {
      return Math.floor((100 * this.pack.price) / this.pack.old_price - 100);
    },
    /**
     * Посчитать N минут чата
     *
     */
    willHaveChatMinutes() {
      const { text_chat } = this.siteSettings;
      return Math.floor(parseInt(this.pack.credit, 10) / parseInt(text_chat, 10));
    },
    /**
     * Посчитать N минут видео чата
     *
     */
    willHaveVideoChatMinutes() {
      const { video_chat, text_chat } = this.siteSettings;
      return Math.floor(
        parseInt(this.pack.credit, 10) / (parseInt(video_chat, 10) + parseInt(text_chat, 10))
      );
    },
    /**
     * Посчитать N сообщений
     *
     */
    canSendLetters() {
      return Math.floor(
        parseInt(this.pack.credit, 10) / parseInt(this.siteSettings.write_letter, 10)
      );
    },
    /**
     * Настройки сайта
     *
     */
    siteSettings() {
      return this.$store.getters.siteSettings;
    },
  },

  methods: {
    /**
     * Открыть модальное окно выбора платежной системы
     */
    openPaySystemModal() {
      this.$store.commit('updatePaySystemModal', {
        open: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.promo-tab-text {
  margin-top: 20px;
}
.credits-pack__title {
  text-align: center;
}
.credits-pack__img {
  flex: none !important;
  width: 150px;
}
.promo-pack-heading {
  justify-content: center;
}
.promo-info-item {
  margin: 0 !important;
  padding: 0 !important;
}
.promo-info {
  display: flex;
  justify-content: center;
  padding: 25px 0;
}
.promo-pack-button {
  display: flex;
  justify-content: center;

  button {
    color: black;
  }
}
</style>
