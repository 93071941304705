<template>
  <div v-if="!loading && packs.length > 0" class="promo-wrapper">
    <div
      class="promo-top"
      :style="{ justifyContent: packs.length > 1 ? 'space-between' : 'center' }"
    >
      <div class="head-elements">
        <h2 v-if="packs.length > 1" class="promo-title">
          <span class="redbini-red-text">C</span>hoose a credit package for amazing features
        </h2>
        <h2 v-else class="promo-title">
          <span class="redbini-red-text">T</span>ry a credit package for amazing features
        </h2>
      </div>
      <div v-if="packs.length > 1" class="tabs-component">
        <ul role="tablist" class="tabs-component-tabs tabs-promo">
          <li
            v-for="(packItem, index) in packs"
            :key="packItem.id"
            :class="{ 'is-active': packItem.id === pack.id }"
            class="tabs-component-tab"
            role="presentation"
            @click="selectPack(index)"
          >
            <div class="tab-header" role="tab">
              <div class="price noselect">{{ packItem.price }}€</div>
              <div class="credits noselect">{{ packItem.credit }} credits</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="promo-body">
      <PromoTab :pack="pack" />
      <p>
        Right after the purchase, you have an opportunity to write a letter to any girl you like!
      </p>
      <p>
        Besides, if you don’t like the package, we will return you the full amount during the first
        three days after purchase.
      </p>
    </div>
    <div
      style="
        background: center / contain no-repeat url('./static/images/im2-min.jpg');
        height: auto;
        width: 100%;
      "
    />
    <div class="promo-bottom">
      <span>We Always Keep Up With The Desires Of Social Network Users</span>
      <span class="tip"> Have fun and fall in <span class="redbini-red-text">love</span>! </span>
    </div>
  </div>
  <div v-else />
</template>

<script>
import creditPacks from '../../../mixins/creditPacks';
import PromoTab from './PromoTab';

export default {
  name: 'Promo',
  components: { PromoTab },
  mixins: [creditPacks],

  data: () => ({
    packs: [],
    pack: null,
    // boxHeight: 0,
  }),

  async mounted() {
    try {
      this.loading = true;
      await this.loadPacks();
      if (!(this.packs.length > 0)) {
        this.$emit('no-promo-packs');
      }
      if (this.packs.length > 1) {
        this.selectPack(Math.floor((this.packs.length - 1) / 2));
      } else {
        this.selectPack(0);
      }
    } catch (e) {
      this.error = true;
      await this.$store.dispatch('addAlert', {
        type: 'error',
        text: this.$t('alerts.someErr'),
        info: 'load credit packs',
      });
    } finally {
      this.loading = false;

      // this.boxHeight = document.querySelector('#veryWhiteBox').clientHeight;
    }
  },

  methods: {
    /**
     * Загрузить promo - кредитпаки
     */
    async loadPacks() {
      const { body } = await this.$http.post('v1/credit/load-promo', {
        access_token: window.localStorage['access-token'],
      });

      if (!body.status) {
        throw new Error('Something went wrong');
      }

      this.packs = body.result;
    },
    /**
     * Выбор пакета
     */
    selectPack(index) {
      this.pack = this.packs[index];
      Object.assign(this.pack, { isPromo: true });
      this.sendSelectEventToParent();
    },
    /**
     * Отправка события выбора пакета в родителя
     */
    sendSelectEventToParent() {
      this.$emit('pack-selected', this.pack);
    },
  },
};
</script>

<style lang="scss" scoped>
$tipo-margins: 25px;
$gradient-edges: 15px;

.redbini-red-text {
  color: #e21919;
}

.promo-wrapper {
  display: grid;
  grid-template-rows: 1.5fr 5fr 240px 1fr;

  @media screen and (max-width: 769px) {
    grid-template-rows: auto 6fr 150px 1fr;
  }
}

.promo-top {
  position: relative;
  z-index: 1;

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .promo-title {
    text-align: center;
    font-size: 2em;
  }
  .head-elements {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    width: 450px;
  }
  @media screen and (max-width: 425px) {
    .promo-title {
      padding-top: 10px;
      text-align: center;
      font-size: 1.8em;
    }
    .head-elements {
      width: auto;
    }
  }
}

.promo-body {
  padding: $tipo-margins;
  font-size: 1.1em;
}

.promo-bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px;
  margin-top: 10px;
  background: #ffffff;

  text-align: center;
  .tip {
    font-weight: lighter;
    font-size: 1.3em;
  }
}

.tabs-promo:before {
  background: url('/static/images/banner_03.png');
  height: 72px;
  width: 100%;
  content: ' ';
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0.9;
  z-index: -1;

  @media screen and (max-width: 769px) {
    background: none;
  }
}

.tabs-promo {
  justify-content: space-around;
  padding: 0;
  margin: 0;
  z-index: 1;
  background: rgba(227, 34, 34, 0.5);
  background: linear-gradient(135deg, #ffffff $gradient-edges, rgba(255, 255, 255, 0.44) 0) left,
    linear-gradient(-45deg, #ffffff $gradient-edges, rgba(255, 255, 255, 0.44) 0) right;

  @media screen and (max-width: 769px) {
    flex-wrap: wrap;
    background: #cca3a319;
    margin: 8px;

    .is-active {
      background: #e219190f;
    }
  }

  li {
    width: 100% !important;
    cursor: pointer;

    @media screen and (min-width: 426px) and (max-width: 769px) {
      width: 45% !important;
    }
  }

  li:last-child:after {
    width: calc(100% - #{$gradient-edges} - 5px);
  }

  .tab-header {
    display: block;
    align-items: center;
    color: inherit;
    padding: 0.75em 1em;
    text-decoration: none;
    text-align: center;
    cursor: pointer;

    .price {
      font-weight: bold;
      font-size: 1.46em;
      cursor: pointer;
    }
    .credits {
      cursor: pointer;
    }
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
