<template>
  <transition name="modal">
    <div
      v-show="modal"
      class="vue-modal modal-mask refund-modal"
      @mousedown="closeModal($event.target)"
    >
      <div class="vue-modal--wrapper">
        <div v-if="transactionData" class="vue-modal--container refund-modal--container">
          <button type="button" class="close" @click="closeModal(false)">
            <span class="rbi rbi-close" />
          </button>
          <div class="modal-body refund-modal--body">
            <h4 class="modal-title">
              {{ $t('refundModal.heading') }}
            </h4>
            <p class="text-muted">
              {{
                $t('refundModal.body')
                  .replace(/{package}/g, transactionData.pack_name)
                  .replace(/{price}/g, transactionData.price)
              }}
            </p>
            <textarea
              v-model="comment"
              v-validate="'required|min:50|max:1000'"
              class="form-control transparent w-full"
              placeholder="Reason of refund"
              rows="4"
              data-vv-as="reason of refund"
              name="comment"
            />
            <div v-show="errors.first('comment')" class="error py-0">
              <i class="fa fa-warning is-danger" />
              <span class="is-danger">{{ errors.first('comment') }}</span>
            </div>
            <div style="display: flex; justify-content: space-between; margin-top: 1rem">
              <button class="btn" @click="submitRefund">
                <span>{{ $t('refundModal.submit') }}</span>
                <span class="rbi rbi-reply" />
              </button>
              <button class="btn" @click="closeModal(false)">
                <span>{{ $t('refundModal.cancel') }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'RefundModal',
  props: {
    transactionData: Object,
  },
  data() {
    return {
      comment: '',
    };
  },
  computed: {
    modal() {
      return this.$store.getters.refundModal.open;
    },
  },
  watch: {
    modal() {
      if (this.comment) this.comment = '';
    },
  },
  methods: {
    /**
     * закрыть модалку
     *
     *
     * @param e {object} event
     */
    closeModal(e) {
      // eslint-disable-next-line no-undef
      if (!$(e).closest('.refund-modal--body').length || !e) {
        this.$store.commit('updateRefundModal', {
          open: false,
        });
      }
    },
    submitRefund() {
      if (!this.transactionData || !this.transactionData.id) {
        this.$store.commit('addAlert', {
          type: 'error',
          text: 'Transaction data fail. Try again later.',
        });

        return this.closeModal(false);
      }

      this.$validator
        .validateAll()
        .then(() => {
          this.$http
            .post('v1/credit/create-refund-request', {
              access_token: window.localStorage['access-token'],
              credit_bought_id: this.transactionData.id,
              comment: this.comment,
            })
            .then(
              ({ data }) => {
                if (!data.status) {
                  this.$store.commit('addAlert', {
                    type: 'error',
                    text: 'Error happened. Try again later.',
                  });
                  return this.closeModal(false);
                }

                this.$store.commit('addAlert', {
                  type: 'success',
                  text: 'Refund request sent. Wait for reply on email.',
                });

                return this.closeModal(false);
              },
              () => {
                this.$store.commit('addAlert', {
                  type: 'error',
                  text: 'Error happened. Try later.',
                });
                return this.closeModal(false);
              }
            );
        })
        .catch(() => {
          this.$store.commit('addAlert', {
            type: 'error',
            text: 'Validation error. Check all fields to be filled.',
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
textarea {
  padding: 5px;
}
textarea::-webkit-input-placeholder {
  color: #c9c9c9;
}

textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #c9c9c9;
}

textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #c9c9c9;
}

textarea:-ms-input-placeholder {
  color: #c9c9c9;
}

textarea::placeholder {
  color: #c9c9c9;
}
.refund-modal {
  &--container {
    width: 555px;
    position: relative;
    @media screen and (max-width: 1024px) {
      width: 100%;
      .btn {
        width: 140px;
        height: 65px;
      }
    }
  }

  h4 {
    font-weight: bold;
  }

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    @media screen and (max-width: 1024px) {
      top: 50px;
      right: 40px;
    }
  }

  &--body {
    text-align: center;
    padding: 20px 30px;
    p {
      margin: 20px 0;
    }
    @media screen and (max-width: 1024px) {
      padding: 50px 10px;
    }
  }

  .btn {
    .rbi {
      margin-left: 0.5em;
    }
  }
}
</style>
