<template>
  <div class="main-block">
    <RefundModal :transaction-data="dataToRefund" />
    <div class="white-box action-page">
      <div class="action-page--heading">
        <h1 class="action-page--heading__h d-flex justify-content-between">
          <span>
            {{ $t('actionPages.titles.paymentsHis') }}
          </span>
        </h1>
      </div>
      <div class="action-page--content">
        <div
          v-show="showPreloader"
          class="alert-actions-wrap"
        >
          <div class="loader--wrap">
            <div class="loader" />
          </div>
        </div>
        <div
          v-show="!storedOrders.length && !showPreloader"
          class="alert-actions-wrap"
        >
          <p class="alert alert-info">
            {{ $t('actionPages.errors.noPayments') }}
          </p>
        </div>
        <div
          v-show="!showPreloader && displayedOrders.length"
          class="order-table--wrap"
        >
          <table class="order-table">
            <tr class="order-table--header order-table--row">
              <td class="order-table--cell order-table--header__cell hidden-xs">
                <div class="relative">
                  {{ $t('actionPages.utils.serialNumber') }}
                  <button
                    type="button"
                    class="btn-sort"
                    @click="sortBySerial"
                  >
                    <span class="rbi rbi-sort" />
                  </button>
                </div>
              </td>
              <td class="order-table--cell order-table--header__cell responsive-width bdtop-l">
                <div class="relative">
                  {{ $t('actionPages.utils.pckName') }}
                  <button
                    type="button"
                    class="btn-sort"
                    @click="sortByType"
                  >
                    <span class="rbi rbi-sort" />
                  </button>
                </div>
              </td>
              <td class="order-table--cell order-table--header__cell hidden-xs">
                <div class="relative">
                  {{ $t('actionPages.utils.credits') }}
                  <button
                    type="button"
                    class="btn-sort"
                    @click="sortByPrice"
                  >
                    <span class="rbi rbi-sort" />
                  </button>
                </div>
              </td>
              <td class="order-table--cell order-table--header__cell hidden-xs">
                <div class="relative">
                  {{ $t('actionPages.utils.price') }}
                  <button
                    type="button"
                    class="btn-sort"
                    @click="sortByPrice"
                  >
                    <span class="rbi rbi-sort" />
                  </button>
                </div>
              </td>
              <td class="order-table--cell order-table--header__cell responsive-width">
                <div class="relative">
                  {{ $t('actionPages.utils.date') }}
                  <button
                    type="button"
                    class="btn-sort"
                    @click="sortByTime"
                  >
                    <span class="rbi rbi-sort" />
                  </button>
                </div>
              </td>
              <td
                class="
                  order-table--cell
                  order-table--header__cell
                  text-center
                  bdtop-r
                  visible-td-xs
                "
              >
                <span class="rbi rbi-sort m0" />
              </td>
            </tr>
            <template v-for="item in displayedOrders">
              <tr
                :key="item.id"
                class="order-table--row"
              >
                <td class="order-table--cell hidden-xs">
                  {{ item.id }}
                </td>
                <td class="order-table--cell">
                  {{ item.pack_name }}
                </td>
                <td class="order-table--cell text-center hidden-xs">
                  {{ item.credits }}
                </td>
                <td class="order-table--cell text-center hidden-xs">
                  <div>{{ `${item.price}` }}</div>
                  <div
                    v-if="item.refund_status !== null"
                    style="padding: 5px"
                  >
                    <rb-button
                      v-if="item.refund_status === 1"
                      style="width: 100px; height: 40px; margin: 9px auto"
                      class="btn-outline"
                      @click.native="refundPromoPackage(item)"
                    >
                      Refund
                    </rb-button>
                    <div
                      v-else-if="item.refund_status !== 0"
                      style="border: 2px dotted grey"
                    >
                      Refund status:
                      <div
                        v-if="item.refund_status === 2"
                        style="color: dodgerblue"
                      >
                        Processing
                      </div>
                      <div
                        v-else-if="item.refund_status === 3"
                        style="color: darkred"
                      >
                        Rejected
                      </div>
                      <div
                        v-else-if="item.refund_status === 4"
                        style="color: green"
                      >
                        Accepted
                      </div>
                    </div>
                  </div>
                </td>
                <td class="order-table--cell">
                  {{ displayDate(item.created_at) }}
                </td>
                <td
                  class="order-table--cell toggle-state text-center visible-td-xs"
                  :class="{ open: item.open }"
                  @click="item.open = !item.open"
                >
                  <span
                    v-if="!item.open"
                    class="rbi rbi-down-arrow m0"
                  />
                  <span
                    v-if="item.open"
                    class="rbi rbi-top-arrow m0"
                  />
                </td>
              </tr>
              <tr
                :key="`${item.id}-opened`"
                class="order-table--row order-table--hidden"
                :class="{ open: item.open }"
              >
                <td colspan="5">
                  <div class="order-table--responsive-record__wrap">
                    <div class="order-table--responsive-record">
                      <div class="order-table--responsive-record__item">
                        {{ $t('actionPages.utils.serialNumber') }}
                      </div>
                      <div class="order-table--responsive-record__item">
                        {{ item.id }}
                      </div>
                    </div>
                    <div class="order-table--responsive-record set-flex">
                      <div class="order-table--responsive-record__item">
                        {{ $t('actionPages.utils.credits') }}
                      </div>
                      <div class="order-table--responsive-record__item">
                        {{ item.credits }}
                      </div>
                    </div>
                    <div class="order-table--responsive-record set-flex">
                      <div class="order-table--responsive-record__item">
                        {{ $t('actionPages.utils.price') }}
                      </div>
                      <div class="order-table--responsive-record__item">
                        {{ `$${item.price}` }}
                      </div>
                    </div>
                    <div class="order-table--responsive-record set-flex">
                      <div class="order-table--responsive-record__item" />
                      <div class="order-table--responsive-record__item" />
                    </div>
                    <div
                      v-if="item.refund_status !== null"
                      style="padding: 5px"
                    >
                      <rb-button
                        v-if="item.refund_status === 1"
                        style="width: 100px; height: 40px; margin: 9px auto"
                        class="btn-outline"
                        @click.native="refundPromoPackage(item)"
                      >
                        Refund
                      </rb-button>
                      <div
                        v-else-if="item.refund_status !== 0"
                        style="border: 2px dotted grey"
                      >
                        Refund status:
                        <div
                          v-if="item.refund_status === 2"
                          style="color: dodgerblue"
                        >
                          Processing
                        </div>
                        <div
                          v-else-if="item.refund_status === 3"
                          style="color: darkred"
                        >
                          Rejected
                        </div>
                        <div
                          v-else-if="item.refund_status === 4"
                          style="color: green"
                        >
                          Accepted
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </table>
          <div class="button-wrap">
            <lazy-load
              v-show="displayLoadMoreBtn"
              :counter="defaultLimit"
              @click.native="limit += defaultLimit"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import LazyLoad from '../parts/LoadMore.vue';
import RbButton from '../../Button';
import RefundModal from './RefundModal';

export default {
  components: {
    RefundModal,
    LazyLoad,
    RbButton,
  },
  data() {
    return {
      showPreloader: true,
      storedOrders: [],
      defaultLimit: 30,
      limit: 30,
      sortState: {
        action: true,
        price: true,
        serial: true,
        time: true,
        quantity: true,
      },
      dataToRefund: null,
    };
  },
  computed: {
    refundModal() {
      return this.$store.getters.refundModal.open;
    },
    /**
     * Показывать ли кнопку "загрузить еще"
     *
     * @return {boolean}
     */
    displayLoadMoreBtn() {
      if (this.isSearching) {
        return this.displayedOrders.length < this.searchResult.length;
      }
      return this.displayedOrders.length < this.storedOrders.length;
    },
    sex() {
      return this.$store.getters.sex;
    },
    orders: {
      get() {
        return this.storedOrders.slice(0, this.limit);
      },
      set(val) {
        val.forEach((i) => (i.open = false));
        this.storedOrders = val;
      },
    },
    displayedOrders() {
      return this.orders;
    },
  },
  watch: {
    /**
     * Получить список заказов, когда получили свой профиль
     */
    sex() {
      this.getOrders();
    },
    refundModal(newVal, oldVal) {
      if (newVal === false && oldVal === true) {
        this.getOrders();
      }
    },
  },
  mounted() {
    document.title = this.$t('actionPages.titles.payments');
    this.getOrders();
  },
  created() {
    this.preventEnter();
  },
  methods: {
    /**
     * Возврат по промо пакету кредитов
     */
    refundPromoPackage(item) {
      this.dataToRefund = item;
      this.$store.commit('updateRefundModal', { open: true });
    },
    /**
     * Получить список заказов
     */
    getOrders() {
      if (this.sex.isMan) {
        this.showPreloader = true;
        this.$http
          .post('v1/credit/refill', {
            access_token: window.localStorage['access-token'],
            sort: 'a-z',
          })
          .then((response) => {
            this.showPreloader = false;
            this.orders = response.body.result;
          });
      }
      this.preventEnter();
    },

    /**
     * Переадресовать пользователя со страницы, если это женщина
     */
    preventEnter() {
      if (this.sex.isWoman) {
        this.$router.push({
          name: '404',
        });
      }
    },

    /**
     * Получить строчное представление даты
     * @param timestamp {number}
     * @return {string}
     */
    displayDate(timestamp) {
      return moment(timestamp * 1000).format('MMM Do, YYYY');
    },

    /**
     * Отсортировать данные по цене
     */
    sortByPrice() {
      this.sortState.price = !this.sortState.price;
      const self = this;
      const sort = function (a, b) {
        if (self.sortState.price) {
          return a.price - b.price;
        }
        return a.price > b.price ? -1 : 1;
      };
      this.storedOrders.sort(sort);
    },
    /**
     * Отсортировать данные по серийному номеру
     */
    sortBySerial() {
      this.sortState.serial = !this.sortState.serial;
      const self = this;
      const sort = function (a, b) {
        if (self.sortState.serial) {
          return a.id - b.id;
        }
        return a.id > b.id ? -1 : 1;
      };
      this.storedOrders.sort(sort);
    },

    /**
     * Отсортировать данные по времени
     */
    sortByTime() {
      this.sortState.time = !this.sortState.time;
      const self = this;
      const sort = function (a, b) {
        if (self.sortState.time) {
          return a.created_at - b.created_at;
        }
        return a.created_at > b.created_at ? -1 : 1;
      };
      this.storedOrders.sort(sort);
    },

    /**
     * Отсортировать данные по типу
     */
    sortByType() {
      this.sortState.action = !this.sortState.action;
      const self = this;
      const sort = function (a, b) {
        if (self.sortState.action) {
          return a.pack_name > b.pack_name ? 1 : -1;
        }
        return a.pack_name < b.pack_name ? 1 : -1;
      };
      this.storedOrders.sort(sort);
    },

    /**
     * Отсортировать данные по кредитам
     */
    sortByCredits() {
      this.sortState.credit = !this.sortState.credit;
      const self = this;
      const sort = function (a, b) {
        if (self.sortState.credit) {
          return a.credit - b.credit;
        }
        return a.credit > b.credit ? -1 : 1;
      };
      this.storedOrders.sort(sort);
    },
  },
};
</script>

<style></style>
